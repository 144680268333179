import { Script } from "gatsby";
import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";

export const SEO = ({ metadata, pathname, children }) => {
  const { title, description, keywords, image, siteUrl, twitterUsername } =
    useSiteMetadata();

  const seo = {
    title: metadata?.title || title,
    description: metadata?.description || description,
    keywords: metadata?.keywords || keywords,
    image: metadata?.image?.asset?.url || `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  };

  return (
    <>
      {/*<script async src="https://www.googletagmanager.com/gtag/js?id=G-1FF979LY8P"></script>*/}
      {/*<script dangerouslySetInnerHTML={{__html: `*/}
      {/*	window.dataLayer = window.dataLayer || [];*/}
      {/*	function gtag(){dataLayer.push(arguments);}*/}
      {/*	gtag('js', new Date());*/}

      {/*	gtag('config', 'G-1FF979LY8P');*/}
      {/*`}}>*/}

      {/*</script>*/}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={"/favicon/apple-touch-icon.png"}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={"/favicon/favicon-32x32.png"}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={"/favicon/favicon-16x16.png"}
      />
      <link rel="manifest" href={"/favicon/site.webmanifest"} />
      <link
        rel="mask-icon"
        href={"/favicon/safari-pinned-tab.svg"}
        color="#010102"
      />
      <meta name="msapplication-TileColor" content="#010102" />
      <meta name="theme-color" content="#010102" />
      <meta
        name="google-site-verification"
        content="eXrzieVsw2EXejiBwkQUOdMLowS7X_MvvOv7S825QdY"
      />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="image" content={seo.image} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      {metadata?.canonical && (
        <link rel="canonical" href={metadata?.canonical} />
      )}
      <meta property="og:type" content={"article"} />
      {metadata?.schema && (
        <script type="application/ld+json">{metadata?.schema}</script>
      )}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Nick Wolny",
                item: "https://nickwolny.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: "https://nickwolny.com/blog",
              },
              {
                "@type": "ListItem",
                position: 3,
                name: "About",
                item: "https://nickwolny.com/about",
              },
              {
                "@type": "ListItem",
                position: 4,
                name: "Camp Wordsmith",
                item: "https://nickwolny.com/camp-wordsmith",
              },
              {
                "@type": "ListItem",
                position: 5,
                name: "Speaking",
                item: "https://nickwolny.com/speaking",
              },
            ],
          }),
        }}
      ></script>
      {/*-- Global site tag (gtag.js) - Google Analytics --*/}
      {/* <Script
        strategy="idle"
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-219515007-1"
      ></Script> */}
      {/* <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', 'UA-219515007-1');
			`,
        }}
      ></Script> */}

      <Script
        strategy="idle"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1483617660112911"
        crossOrigin="anonymous"
        async
        key="google-adsense-script"
      />
      {/* <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `!function(e,t)
  {
    (((e = t.createElement("script")).src =
      "https://cdn.convertbox.com/convertbox/js/embed.js"),
    (e.id = "app-convertbox-script"),
    (e.async = true),
    (e.dataset.uuid = "a1ce5f9e-d630-4668-be5b-3554f61e94a7"),
    document.getElementsByTagName("head")[0].appendChild(e))
  }
  (window,document);`,
        }}
      ></script> */}
      {/*<meta name="twitter:card" content="summary_large_image" />*/}
      {/*<meta name="twitter:title" content={seo.title} />*/}
      {/*<meta name="twitter:url" content={seo.url} />*/}
      {/*<meta name="twitter:description" content={seo.description} />*/}
      {/*<meta name="twitter:image" content={seo.image} />*/}
      {/*<meta name="twitter:creator" content={seo.twitterUsername} />*/}

      {children}
    </>
  );
};
